.HeaderImageLink {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.headerImg {
  height: 45px;
  object-fit: contain;
  width: auto;
}

.cnfcImage {
  height: 57px;
}

.logoGobierno {
  margin-left: 4rem;
}

@media (max-width: 380px) {
  .cnfcImage {
    height: 45px;
  }
}
